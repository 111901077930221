import { createContext, useEffect, useState } from 'react';
import { deviceService } from '../../Api/Services/DeviceService';
import { DeviceModel } from '../../Models/device.model';
import { useLoading } from './LoadingProvider';

interface DeviceContextInterface {
    device: DeviceModel;
}

interface DeviceProviderPropsInterface {
    children: JSX.Element;
}

export const deviceIdStorageKey = 'deviceId';

const DeviceContext = createContext<DeviceContextInterface>({ device: { id: '' } });

export const DeviceProvider = ({ children }: DeviceProviderPropsInterface) => {
    const [device, setDevice] = useState<DeviceModel | null>(null);
    const { startLoading, finnishLoading } = useLoading();

    useEffect(() => {
        const storageDeviceId = localStorage.getItem(deviceIdStorageKey);

        if (storageDeviceId) {
            startLoading('verify-device');
            deviceService
                .verifyDeviceId(storageDeviceId)
                .then((device) => {
                    localStorage.setItem(deviceIdStorageKey, device.id);
                    setDevice(device);
                })
                .finally(() => {
                    finnishLoading('verify-device');
                });
        } else {
            startLoading('genereate-device');
            deviceService
                .generateDeviceId()
                .then((device) => {
                    localStorage.setItem(deviceIdStorageKey, device.id);
                    setDevice(device);
                })
                .finally(() => {
                    finnishLoading('genereate-device');
                });
        }
        //eslint-disable-next-line
    }, []);

    if (!device) {
        return <></>;
    }

    return <DeviceContext.Provider value={{ device }}>{device ? children : null}</DeviceContext.Provider>;
};

export default DeviceContext;
