import Echo from 'laravel-echo';
import { FileModel } from '../../../../Models/file.model';
import { useEffect, useRef, useState } from 'react';
import { p2pService } from '../../../../Api/Services/P2PService';
import { DeviceModel } from '../../../../Models/device.model';
import { FileSizeConvertToReadable } from '../../../../Api/Services/HelperFunctions/FileSizeConvertToReadable';
import { Progress } from 'flowbite-react';

interface P2PFileSendProps {
    file: FileModel;
    localFile: File;
    currentDeviceId: string;
    toDeviceId: string;
    roomId: string;
    echo: Echo;
    setFileSendDone: CallableFunction;
}

interface ConnectionAnswerPayload {
    answer: RTCSessionDescriptionInit;
    senderDevice: DeviceModel;
}

interface ConnectionIceCandidatePayload {
    candidates: RTCIceCandidate[];
    senderDevice: DeviceModel;
    from: string;
}

const rtcConfiguration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };

const P2PFileSend: React.FunctionComponent<P2PFileSendProps> = ({ file, localFile, toDeviceId, currentDeviceId, roomId, echo, setFileSendDone }) => {
    const iceCandidates = useRef<RTCIceCandidate[]>([]);
    const connection = useRef<RTCPeerConnection>();

    const [sendSizeProgress, setSendSizeProgress] = useState<number>(0);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const startTimeStamp = useRef<number>(Date.now());

    useEffect(() => {
        echo.channel(`rtc-connection.${roomId}.${currentDeviceId}.${file.id}`).listen('ConnectionAnswer', (e: ConnectionAnswerPayload) => {
            console.log('echo answer', e);

            if (e.answer) {
                if (connection.current) {
                    console.log('set answer', connection.current, e.answer);

                    if (!e.answer.sdp?.endsWith('\n')) {
                        e.answer.sdp = e.answer.sdp + '\n';
                    }
                    connection.current.setRemoteDescription(e.answer).then(() => {
                        console.log('Sendign send ice');

                        if (iceCandidates.current.length > 0) {
                            p2pService.iceCandidate(iceCandidates.current, roomId, 'send', e.senderDevice.id, file.id);
                        }
                    });
                }
            }
        });

        echo.channel(`rtc-connection.${roomId}.${currentDeviceId}.${file.id}`).listen('NewIceCandidate', (e: ConnectionIceCandidatePayload) => {
            console.log('echo send ice', e);

            if (e.candidates) {
                e.candidates.forEach((candidate) => {
                    if (connection.current) {
                        connection.current.addIceCandidate(candidate);
                    }
                });
            }
        });

        connection.current = new RTCPeerConnection(rtcConfiguration);

        const sendChannel = connection.current.createDataChannel('sendDataChannel');
        sendChannel.binaryType = 'arraybuffer';

        //TODO
        sendChannel.onopen = (e) => {
            if (sendChannel.readyState === 'open') {
                console.log('SENDING FILE', file);
                startTimeStamp.current = Date.now();

                const chunkSize = 65535;
                let fileReader = new FileReader();
                let offset = 0;
                fileReader.addEventListener('error', (error) => console.error('Error reading file:', error));
                fileReader.addEventListener('abort', (event) => console.log('File reading aborted:', event));

                fileReader.addEventListener('load', (e) => {
                    console.log('FileRead.onload ', e);
                    if (e.target?.result && e.target.result instanceof ArrayBuffer) {
                        console.log(e.target.result);

                        sendChannel.send(e.target.result);
                        offset += e.target.result?.byteLength;
                        setSendSizeProgress(offset);
                        setElapsedTime(Date.now() - startTimeStamp.current);
                    }
                });

                const readSlice = (o: number) => {
                    console.log('readSlice ', o);
                    const slice = localFile.slice(o, o + chunkSize);
                    fileReader.readAsArrayBuffer(slice);
                };

                sendChannel.onbufferedamountlow = () => {
                    if (offset < localFile.size) {
                        readSlice(offset);
                    } else {
                        console.log('done');
                        setFileSendDone(file.id, toDeviceId);
                    }
                };
                readSlice(0);
            }
            console.log('SEND CHANNEL OPEN', e);
        };

        sendChannel.onclose = (e) => {
            console.log('SEND CHANNEL CLOSE', e);
        };

        sendChannel.onmessage = (event) => {
            console.log('recieve ', event.data);
        };

        connection.current.onicecandidate = (e) => {
            if (e.candidate) {
                iceCandidates.current?.push(e.candidate);
                //p2pService.iceCandidate(e.candidate, room.id, 'send', device.id);
            }
        };
        console.log('CURRENT CONNECTION', connection.current);

        connection.current
            .createOffer()
            .then((offer) => {
                console.log('OFFER created', offer);
                if (connection.current) {
                    connection.current.setLocalDescription(offer).then(() => {
                        if (connection.current?.localDescription) {
                            console.log('sending offer');

                            p2pService.makeOffer(connection.current.localDescription, roomId, toDeviceId, file.id);
                        }
                    });
                }
            })
            .catch((e) => console.log(e));

        return () => {
            echo.channel(`rtc-connection.${roomId}.${toDeviceId}`).stopListening('ConnectionAnswer');
            echo.channel(`rtc-connection.${roomId}.${toDeviceId}`).stopListening('NewIceCandidate');
            echo.leave(`rtc-connection.${roomId}.${toDeviceId}`);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="flex justify-between">
                <div className="text-base font-medium dark:text-white tabular-nums">Küldés: {FileSizeConvertToReadable(sendSizeProgress / (elapsedTime / 1000), true)}</div>
                <div className="text-base font-medium dark:text-white tabular-nums">{((sendSizeProgress / file.fileSize) * 100).toFixed(1)}%</div>
            </div>
            <Progress progress={(sendSizeProgress / file.fileSize) * 100} labelProgress={true} />
        </div>
    );
};

export default P2PFileSend;
