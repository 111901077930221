import { Link, NavLink, Outlet } from 'react-router-dom';
import UserContext from '../Context/Providers/UserProvider';
import { useContext, useState } from 'react';
import { Navbar, Dropdown, Avatar, Button } from 'flowbite-react';
import AuthModal, { FormType } from '../Pages/Auth/AuthModal';
import CollectionIcon from '@heroicons/react/outline/CollectionIcon';
import ChevronDoubleRightIcon from '@heroicons/react/outline/ChevronDoubleRightIcon';
import PlusCircleIcon from '@heroicons/react/outline/PlusCircleIcon';
import XIcon from '@heroicons/react/outline/XIcon';
import CreateRoomModal from '../Pages/CreateRoomModal';

interface LayoutProps {}

const activeNavLinkClasses = 'block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-orange-600 md:p-0 dark:text-white lg:text-lg';
const defaultNavLinkClasses =
    'block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent lg:text-lg cursor-pointer';

const Layout: React.FunctionComponent<LayoutProps> = () => {
    const { user, logout } = useContext(UserContext);

    const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
    const [showCreateRoomModal, setShowCreateRoomModal] = useState<boolean>(false);

    const [formType, setFromType] = useState<FormType>(FormType.LOGIN);
    const [drawerOpen, setDarawerOpen] = useState<boolean>(false);

    const switchFormType = (newFormType: FormType) => {
        setFromType(newFormType);
    };

    const handleLogout = () => {
        logout();
    };

    const closeAuthModal = () => {
        setShowAuthModal(false);
    };

    const closeCreateRoomModal = () => {
        setShowCreateRoomModal(false);
    };

    return (
        <>
            {drawerOpen && <div drawer-backdrop="" className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30 block md:hidden"></div>}
            <div
                id="drawer-navigation"
                className={'block md:hidden fixed z-40 h-screen p-4 overflow-y-auto bg-white w-80 transition-transform dark:bg-gray-800 ' + (drawerOpen ? 'transform-none' : '-translate-x-full')}
                tabIndex={-1}
            >
                <Link to="/" id="drawer-navigation-label" className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400 block">
                    <img src="/logo.png" className="mr-3 h-10 sm:h-6 inline" alt="Logo" /> <span>Menü</span>
                </Link>
                <button
                    type="button"
                    data-drawer-dismiss="drawer-navigation"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                        setDarawerOpen(false);
                    }}
                >
                    <XIcon className="w-5 h-5" />
                </button>
                <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2">
                        <li>
                            <Link
                                to="/"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={() => setDarawerOpen(false)}
                            >
                                <CollectionIcon className="w-6 h-6" />
                                <span className="ml-3">Szobák</span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/join"
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={() => setDarawerOpen(false)}
                            >
                                <ChevronDoubleRightIcon className="w-6 h-6" />

                                <span className="ml-3">Csatlakozás</span>
                            </Link>
                        </li>

                        <li>
                            <span
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                onClick={() => {
                                    setDarawerOpen(false);
                                    setShowCreateRoomModal(true);
                                }}
                            >
                                <PlusCircleIcon className="w-6 h-6" />
                                <span className="ml-3">Szoba létrehozása</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="md:m-5">
                <AuthModal show={showAuthModal} close={closeAuthModal} formType={formType} switchFormType={switchFormType} />
                <CreateRoomModal show={showCreateRoomModal} close={closeCreateRoomModal} filesToShare={null} />
                <Navbar fluid={true} className="drop-shadow-lg mb-3 md:rounded-lg">
                    <Link to="/" className="items-center hidden md:flex">
                        <img src="/logo.png" className="mr-3 md:h-14 sm:h-12" alt="Logo" />
                        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Szakdoga</span>
                    </Link>

                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <NavLink to="/" end className={({ isActive }) => (isActive ? activeNavLinkClasses : defaultNavLinkClasses)}>
                                    Szobák
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/join" className={({ isActive }) => (isActive ? activeNavLinkClasses : defaultNavLinkClasses)}>
                                    Csatlakozás
                                </NavLink>
                            </li>

                            <li>
                                <span className={defaultNavLinkClasses} onClick={() => setShowCreateRoomModal(true)}>
                                    Szoba létrehozása
                                </span>
                            </li>
                        </ul>
                    </div>

                    <button
                        data-drawer-target="drawer-navigation"
                        data-drawer-show="drawer-navigation"
                        type="button"
                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        onClick={() => setDarawerOpen(!drawerOpen)}
                    >
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>

                    <div className="flex md:order-2">
                        {user ? (
                            <Dropdown arrowIcon={false} inline={true} label={<Avatar alt="User settings" img={user?.avatar ?? ''} rounded={true} bordered={true} />}>
                                <Dropdown.Header>
                                    <>
                                        <span className="block text-sm">{user?.name}</span>
                                        <span className="block truncate text-sm font-medium">{user?.email}</span>
                                    </>
                                </Dropdown.Header>
                                <Dropdown.Item>Beállítások</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleLogout}>Kilépés</Dropdown.Item>
                            </Dropdown>
                        ) : (
                            <>
                                <Button
                                    className="mr-2"
                                    size="sm"
                                    onClick={() => {
                                        setFromType(FormType.LOGIN);
                                        setShowAuthModal(true);
                                    }}
                                >
                                    Bejelentkezés
                                </Button>

                                <Button
                                    className="hidden md:inline-block"
                                    size="sm"
                                    outline={true}
                                    onClick={() => {
                                        setFromType(FormType.REGISTER);
                                        setShowAuthModal(true);
                                    }}
                                >
                                    Regisztráció
                                </Button>
                            </>
                        )}
                    </div>
                </Navbar>
                <div className="w-full flex justify-center">
                    <div className="xl:w-1/2 md:w-2/3 sm:w-5/6 w-full">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
