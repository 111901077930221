import { useNavigate, useParams } from 'react-router-dom';
import { FormEvent, useEffect, useState } from 'react';
import { roomService } from '../Api/Services/RoomService';
import { Button, Label, Spinner, TextInput } from 'flowbite-react';
import QrReaderModal from './QrReaderModal';

interface JoinRoomProps {}

const JoinRoom: React.FunctionComponent<JoinRoomProps> = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [code, setCode] = useState<number>();

    const [joinLoading, setJoinLoading] = useState<boolean>(false);
    const [showQrReader, setShowQrReader] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            joinRoomId(id);
        }
        // eslint-disable-next-line
    }, []);

    const handleCodeJoin = async (event: FormEvent) => {
        event.preventDefault();
        if (code) {
            setJoinLoading(true);
            roomService
                .joinRoomCode(code)
                .then((room) => {
                    navigate(`/room/${room.id}`, { state: { room: room } });
                })
                .finally(() => {
                    setJoinLoading(false);
                });
        }
    };

    const joinRoomId = async (id: string) => {
        setJoinLoading(true);

        roomService
            .joinRoomId(id)
            .then((room) => {
                navigate(`/room/${room.id}`, { state: { room: room } });
            })
            .finally(() => {
                setJoinLoading(false);
            });
    };

    const closeQrReader = () => {
        setShowQrReader(false);
    };

    return (
        <div className="text-center items-center flex flex-col">
            <QrReaderModal show={showQrReader} close={closeQrReader} />

            <h1 className="text-2xl font-bold">Csatlakozás</h1>
            <svg
                onClick={() => setShowQrReader(!showQrReader)}
                className="w-1/5 my-4 cursor-pointer fill-slate-800 hover:fill-slate-600 transition-colors duration-200"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.61 122.88"
            >
                <path
                    d="M26.68,26.77H51.91V51.89H26.68V26.77ZM35.67,0H23.07A22.72,22.72,0,0,0,14.3,1.75a23.13,23.13,0,0,0-7.49,5l0,0a23.16,23.16,0,0,0-5,7.49A22.77,22.77,0,0,0,0,23.07V38.64H10.23V23.07a12.9,12.9,0,0,1,1-4.9A12.71,12.71,0,0,1,14,14l0,0a12.83,12.83,0,0,1,9.07-3.75h12.6V0ZM99.54,0H91.31V10.23h8.23a12.94,12.94,0,0,1,4.9,1A13.16,13.16,0,0,1,108.61,14l.35.36h0a13.07,13.07,0,0,1,2.45,3.82,12.67,12.67,0,0,1,1,4.89V38.64h10.23V23.07a22.95,22.95,0,0,0-6.42-15.93h0l-.37-.37a23.16,23.16,0,0,0-7.49-5A22.77,22.77,0,0,0,99.54,0Zm23.07,99.81V82.52H112.38V99.81a12.67,12.67,0,0,1-1,4.89,13.08,13.08,0,0,1-2.8,4.17,12.8,12.8,0,0,1-9.06,3.78H91.31v10.23h8.23a23,23,0,0,0,16.29-6.78,23.34,23.34,0,0,0,5-7.49,23,23,0,0,0,1.75-8.8ZM23.07,122.88h12.6V112.65H23.07A12.8,12.8,0,0,1,14,108.87l-.26-.24a12.83,12.83,0,0,1-2.61-4.08,12.7,12.7,0,0,1-.91-4.74V82.52H0V99.81a22.64,22.64,0,0,0,1.67,8.57,22.86,22.86,0,0,0,4.79,7.38l.31.35a23.2,23.2,0,0,0,7.5,5,22.84,22.84,0,0,0,8.8,1.75Zm66.52-33.1H96v6.33H89.59V89.78Zm-12.36,0h6.44v6H70.8V83.47H77V77.22h6.34V64.76H89.8v6.12h6.12v6.33H89.8v6.33H77.23v6.23ZM58.14,77.12h6.23V70.79h-6V64.46h6V58.13H58.24v6.33H51.8V58.13h6.33V39.33h6.43V58.12h6.23v6.33h6.13V58.12h6.43v6.33H77.23v6.33H70.8V83.24H64.57V95.81H58.14V77.12Zm31.35-19h6.43v6.33H89.49V58.12Zm-50.24,0h6.43v6.33H39.25V58.12Zm-12.57,0h6.43v6.33H26.68V58.12ZM58.14,26.77h6.43V33.1H58.14V26.77ZM26.58,70.88H51.8V96H26.58V70.88ZM32.71,77h13V89.91h-13V77Zm38-50.22H95.92V51.89H70.7V26.77Zm6.13,6.1h13V45.79h-13V32.87Zm-44,0h13V45.79h-13V32.87Z"
                    fillRule="evenodd"
                />
            </svg>

            <div className="relative flex py-5 items-center w-2/3">
                <div className="flex-grow border-t border-gray-600"></div>
                <span className="flex-shrink mx-4 text-orange-600">Vagy</span>
                <div className="flex-grow border-t border-gray-600"></div>
            </div>

            <form onSubmit={handleCodeJoin} className="w-2/3 md:w-1/2">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="code" value="Szoba kód" className="text-lg" />
                    </div>
                    <TextInput
                        className="border-red-600"
                        id="code"
                        type="number"
                        placeholder="Szoba kód"
                        required={true}
                        onChange={(e) => setCode(+e.target.value)}
                        autoComplete="off"
                        min={100000}
                        max={999999}
                    />
                </div>
                <Button type="submit" disabled={joinLoading} size="sm" className="w-full mt-4">
                    {joinLoading && <Spinner light={true} className="mr-2 w-4 h-4" color="warning" />}
                    Csatlakozás
                </Button>
            </form>
        </div>
    );
};

export default JoinRoom;
