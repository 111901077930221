import { Button, Modal, Spinner, Tooltip } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { roomService } from '../../Api/Services/RoomService';
import { RoomModel } from '../../Models/room.model';
import ClipboardCopyIcon from '@heroicons/react/outline/ClipboardCopyIcon';

interface ShareModalProps {
    show: boolean;
    close: CallableFunction;
    room: RoomModel;
}

const ShareRoomModal: React.FunctionComponent<ShareModalProps> = ({ show, close, room }) => {
    const [roomCode, setRoomCode] = useState<string | null>(null);
    const roomUrl = `${process.env.REACT_APP_BASE_URL}/join/${room.id}`;

    useEffect(() => {
        if (show && !roomCode) {
            roomService.generateCode(room.id).then((roomCode) => {
                setRoomCode(roomCode.code);
            });
        }
        //eslint-disable-next-line
    }, [show]);

    const copyRoomUrl = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(roomUrl);
        } else {
            /*if (roomUrlInputRef.current) {
                roomUrlInputRef.current.focus();
                roomUrlInputRef.current.select();
                document.execCommand('copy');
            }*/
        }
    };

    return (
        <Modal show={show} size="md" popup={true} onClose={() => close()}>
            <Modal.Header className="ml-4">Szoba megosztása</Modal.Header>
            <hr />
            <Modal.Body className="mt-2 flex flex-col justify-center align-middle items-center">
                <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl=${roomUrl}`} alt="qr" className="h-60 w-60" />

                <div className="relative flex py-5 items-center w-2/3">
                    <div className="flex-grow border-t border-gray-600"></div>
                    <span className="flex-shrink mx-4 text-orange-600">Vagy</span>
                    <div className="flex-grow border-t border-gray-600"></div>
                </div>

                <div className="text-center">
                    <h3>Szoba kód:</h3>
                    {!roomCode ? <Spinner /> : <span className="font-bold text-xl">{roomCode}</span>}
                </div>

                <div className="relative flex py-5 items-center w-2/3">
                    <div className="flex-grow border-t border-gray-600"></div>
                    <span className="flex-shrink mx-4 text-orange-600">Vagy</span>
                    <div className="flex-grow border-t border-gray-600"></div>
                </div>

                <div className="w-full flex justify-center ">
                    <Tooltip content="Url másolva" trigger="click">
                        <Button size="sm" className="w-fit grow ml-2" onClick={copyRoomUrl}>
                            <ClipboardCopyIcon className="w-6 h-6 mr-2" />
                            Url másolása
                        </Button>
                    </Tooltip>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShareRoomModal;
