import Echo from 'laravel-echo';
import { FileModel } from '../../../../Models/file.model';
import { useState } from 'react';
import { fileService } from '../../../../Api/Services/FileService';
import P2PFileReceive from './P2PFileRecieve';
import { FileSizeConvertToReadable } from '../../../../Api/Services/HelperFunctions/FileSizeConvertToReadable';
import DocumentIcon from '@heroicons/react/outline/DocumentIcon';
import { Button } from 'flowbite-react';
import DownloadIcon from '@heroicons/react/outline/DownloadIcon';
import SaveIcon from '@heroicons/react/outline/SaveIcon';

interface P2PRemoteFileProps {
    roomId: string;
    currentDeviceId: string;
    file: FileModel;
    echo: Echo;
}

interface FileReceive {
    ownerDeviceId: string;
}

const P2PRemoteFile: React.FunctionComponent<P2PRemoteFileProps> = ({ file, currentDeviceId, roomId, echo }) => {
    const [fileReceive, setFileReceive] = useState<FileReceive>();

    const [remoteFile, setRemoteFile] = useState<Blob>();

    return (
        <>
            <div className="flex flex-row content-center mt-2">
                <DocumentIcon className="h-6 w-6 stroke-rose-800" />
                <span className="ml-2 w-64">{file.fileName.length > 30 ? file.fileName.substring(0, 30) + '...' : file.fileName}</span>
                <span>({FileSizeConvertToReadable(file.fileSize)})</span>
                {!remoteFile && (
                    <Button
                        size="xs"
                        color="success"
                        className="ml-auto disabled:opacity-50"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.currentTarget.disabled = true;

                            setFileReceive({ ownerDeviceId: file.device.id });

                            setTimeout(() => {
                                fileService.requestP2PFileSend(roomId, file.id);
                            }, 200);
                        }}
                    >
                        <DownloadIcon className="h-4 w-4" />
                        &nbsp;Letöltés
                    </Button>
                )}
                {remoteFile && (
                    <Button
                        size="xs"
                        color="purple"
                        className="ml-auto"
                        onClick={() => {
                            const url = window.URL.createObjectURL(remoteFile);
                            let tempLink = document.createElement('a');
                            tempLink.href = url;
                            tempLink.setAttribute('download', file.fileName);
                            tempLink.click();
                        }}
                    >
                        <SaveIcon className="h-4 w-4" />
                        &nbsp;Mentés saját eszközre
                    </Button>
                )}
            </div>
            {fileReceive && (
                <div className="mx-12 my-2 border-t">
                    {!remoteFile && (
                        <P2PFileReceive
                            echo={echo}
                            file={file}
                            roomId={roomId}
                            currentDeviceId={currentDeviceId}
                            key={'p2p-file-receive-from-' + fileReceive.ownerDeviceId}
                            setRemoteFile={setRemoteFile}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default P2PRemoteFile;
