import { useRef, useState } from 'react';
import { RoomModel } from '../../../../Models/room.model';
import { useEffect } from 'react';
import useDevice from '../../../../Context/Hooks/useDevice';
import { FileModel } from '../../../../Models/file.model';
import { fileService } from '../../../../Api/Services/FileService';
import P2PRemoteFile from './P2PRemoteFile';
import LocalFileManager from './LocalFileManager';
import FileDropzone from '../../Components/FileDropzone';
import { useEcho } from '../../../../Context/Providers/EchoProvider';
import { useLocation } from 'react-router-dom';

interface FileAddedToRoomPayload {
    files: FileModel[];
}

interface FilesRemovedPayload {
    fileIds: string[];
}

interface P2PRoomProps {
    room: RoomModel;
}

const P2PRoom: React.FunctionComponent<P2PRoomProps> = ({ room }) => {
    const { echo } = useEcho();

    const [localFiles, setLocalFiles] = useState<FileModel[]>([]);
    const [remoteFiles, setRemoteFiles] = useState<FileModel[]>(room?.files ?? []);
    const remoteFilesRef = useRef<FileModel[]>(room?.files ?? []);

    const DeviceContext = useDevice();
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('beforeunload', (ev) => {
            fileService.leaveP2PRoom(room.id);

            window.onbeforeunload = null;
        });

        echo.channel(`room-files.${room.id}`).listen('FilesAddedToRoom', (newFilePayload: FileAddedToRoomPayload) => {
            console.log('new file');

            if (newFilePayload.files) {
                console.log(newFilePayload.files);

                const newRemoteFiles = newFilePayload.files.filter((x) => x.device.id !== DeviceContext.device?.id);

                console.log('new files merge', remoteFiles, remoteFilesRef.current, newRemoteFiles);

                remoteFilesRef.current = [...remoteFilesRef.current, ...newRemoteFiles];

                setRemoteFiles(remoteFilesRef.current);
            }
        });

        echo.channel(`room-files.${room.id}`).listen('FilesRemovedFromRoom', (filesRemoved: FilesRemovedPayload) => {
            console.log('files removed', filesRemoved.fileIds);

            if (filesRemoved.fileIds.length > 0) {
                remoteFilesRef.current = remoteFilesRef.current.filter((x) => !filesRemoved.fileIds.includes(x.id));
                setRemoteFiles(remoteFilesRef.current);
                console.log('setLocalFiles new files', remoteFilesRef.current);
            }
        });

        return () => {
            fileService.leaveP2PRoom(room.id);
            window.onbeforeunload = null;
            echo.channel(`room-files.${room.id}`).stopListening('FileAddedToRoom');
            echo.channel(`room-files.${room.id}`).stopListening('FilesRemovedFromRoom');
            echo.leave(`room-files.${room.id}`);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setRemoteFiles(room.files);
    }, [room]);

    const handleFileAdd = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map((file) => {
            return {
                fileName: file.name,
                fileSize: file.size
            };
        });

        fileService.addP2PFiles(room.id, newFiles).then((newFiles: FileModel[]) => {
            newFiles.forEach((newFile, index) => {
                newFile.localFile = acceptedFiles[index];
            });
            setLocalFiles([...localFiles, ...newFiles]);
        });
    };

    const handleFileRemove = (fileId: string) => {
        console.log('handleFileRemove file:', fileId);

        fileService.removeP2PFiles(room.id, [fileId]).then(() => {
            let localFilesState = localFiles;
            console.log('handleFileRemove', remoteFiles, localFilesState);
            localFilesState = localFilesState.filter((x) => x.id !== fileId);

            setLocalFiles(localFilesState);
        });
    };

    useEffect(() => {
        if (location.state?.filesToShare) {
            handleFileAdd(location.state.filesToShare);
            window.history.replaceState({}, document.title);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            {DeviceContext?.device?.id && <LocalFileManager currentDeviceId={DeviceContext.device.id} echo={echo} handleFileRemove={handleFileRemove} localFiles={localFiles} roomId={room.id} />}

            <div className="mt-8 p-4 bg-white shadow-md rounded">
                <p className="font-bold underline mb-3 text-lg">Távoli fájlok</p>
                {localFiles.length === 0 && <p>A szoba többi tagjai még nem osztottak meg egy fájlt sem.</p>}
                {remoteFiles.map((roomFile: FileModel, index: number) => (
                    <div className="my-2" key={'file-' + roomFile.id}>
                        {index !== 0 && <hr />}
                        {DeviceContext?.device?.id ? <P2PRemoteFile echo={echo} currentDeviceId={DeviceContext.device.id} file={roomFile} roomId={room.id} /> : <></>}
                    </div>
                ))}
            </div>

            <FileDropzone handleFileAdd={handleFileAdd} />
        </div>
    );
};

export default P2PRoom;
