import { FormEvent, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from '../../Api/Services/UserService';
import UserContext from '../../Context/Providers/UserProvider';
import { Label, TextInput, Button, Spinner } from 'flowbite-react';

interface LoginProps {
    actionLoading: CallableFunction;
    setModalActionLoading: CallableFunction;
    close: CallableFunction;
}

const Login: React.FunctionComponent<LoginProps> = ({ actionLoading, setModalActionLoading, close }) => {
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const { setNewUser } = useContext(UserContext);

    const navigate = useNavigate();

    const [passwordLoginLoading, setPasswordLoginLoading] = useState<boolean>(false);

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();

        setPasswordLoginLoading(true);
        setModalActionLoading(true);

        if (emailRef.current && passwordRef.current) {
            userService
                .login(emailRef.current.value, passwordRef.current.value)
                .then((user) => {
                    setNewUser(user);
                    navigate('/');
                    close();
                })
                .catch((e) => {
                    /*if (e?.response?.data?.message) {
                        alert(e?.response?.data?.message);
                    }*/
                })
                .finally(() => {
                    setPasswordLoginLoading(false);
                    setModalActionLoading(false);
                });
        }
    };

    return (
        <>
            <form onSubmit={handleLogin} className="flex flex-col gap-4 w-full">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="email" value="Email cím" />
                    </div>
                    <TextInput id="email" type="email" placeholder="Email cím" required={true} ref={emailRef} autoComplete="email" />
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password" value="Jelszó" />
                    </div>
                    <TextInput id="password" type="password" required={true} ref={passwordRef} placeholder="Jelszó" autoComplete="current-password" />
                </div>
                <Button type="submit" disabled={actionLoading()} size="sm">
                    {passwordLoginLoading && <Spinner light={true} className="mr-2 w-4 h-4" color="warning" />}
                    Belépés
                </Button>
            </form>
        </>
    );
};

export default Login;
