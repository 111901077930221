import request, { Method } from '../request';

class P2PService {
    async makeOffer(offer: RTCSessionDescriptionInit, roomId: string, toDeviceId: string, fileId: string) {
        return request(`/room/p2p/make-offer`, Method.POST, {
            data: {
                offer: offer,
                roomId: roomId,
                toDeviceId: toDeviceId,
                fileId: fileId
            }
        });
    }

    async answerOffer(answer: RTCSessionDescriptionInit, roomId: string, toDeviceId: string, fileId: string) {
        return request(`/room/p2p/answer-offer`, Method.POST, {
            data: {
                answer: answer,
                roomId: roomId,
                toDeviceId: toDeviceId,
                fileId: fileId
            }
        });
    }

    async iceCandidate(candidate: RTCIceCandidate[], roomId: string, from: string, toDeviceId: string, fileId: string) {
        return request(`/room/p2p/ice-candidate`, Method.POST, {
            data: {
                candidates: candidate,
                roomId: roomId,
                from: from,
                toDeviceId: toDeviceId,
                fileId: fileId
            }
        });
    }
}

export const p2pService = new P2PService();
