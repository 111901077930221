import CloudUploadIcon from '@heroicons/react/outline/CloudUploadIcon';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';

interface FileDropzoneProps {
    handleFileAdd: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
}

const FileDropzone: React.FunctionComponent<FileDropzoneProps> = ({ handleFileAdd }) => {
    return (
        <Dropzone onDrop={handleFileAdd}>
            {({ getRootProps, getInputProps }) => (
                <section className="flex items-center justify-center w-full mt-20">
                    <label
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-[#f6f3f3] dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        {...getRootProps()}
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <CloudUploadIcon className="w-10 h-10 mb-3 text-gray-400" />
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                A fájl feltöltéshez <span className="font-semibold">kattints</span> vagy <span className="font-semibold">dobd ide</span> a fájlokat
                            </p>
                        </div>
                    </label>
                    <input type="file" className="hidden" {...getInputProps()} />
                </section>
            )}
        </Dropzone>
    );
};

export default FileDropzone;
