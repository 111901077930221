import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { roomService } from '../../Api/Services/RoomService';
import { RoomModel } from '../../Models/room.model';
import CreateRoomModal from '../CreateRoomModal';
import RoomItem from './RoomItem';
import RoomItemSkeleton from './RoomItemSkeleton';

export default function Landing() {
    const [joinedRooms, setJoinedRooms] = useState<RoomModel[]>([]);
    const [getRoomsLoading, setGetRoomsLoading] = useState<boolean>(true);
    const [showCreateRoomModal, setShowCreateRoomModal] = useState<boolean>(false);

    useEffect(() => {
        roomService
            .getJoinedRooms()
            .then((rooms) => {
                setJoinedRooms(rooms);
            })
            .finally(() => {
                setGetRoomsLoading(false);
            });
    }, []);

    const closeCreateRoomModal = () => {
        setShowCreateRoomModal(false);
    };

    if (getRoomsLoading) {
        return (
            <div className="p-2 w-full flex-col justify-center">
                {[0, 1, 2, 3, 4].map((i) => (
                    <React.Fragment key={i}>
                        {i !== 0 && <hr className="mt-4" />}
                        <RoomItemSkeleton />
                    </React.Fragment>
                ))}
            </div>
        );
    }

    return (
        <div className="p-2 w-full flex justify-center">
            <CreateRoomModal show={showCreateRoomModal} close={closeCreateRoomModal} filesToShare={null} />

            {joinedRooms.length === 0 ? (
                <h3 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg text-center">
                    Még nem csatlakoztál egy szobához sem, <br className="sm:hidden" />
                    <span className="underline font-bold text-orange-600 hover:text-blue-500 cursor-pointer" onClick={() => setShowCreateRoomModal(true)}>
                        hozz létre egy saját szobát
                    </span>
                    <br className="md:hidden" />
                    &nbsp;vagy&nbsp;
                    <br className="md:hidden" />
                    <Link to="/join" className="underline font-bold text-orange-600 hover:text-blue-500">
                        csatlakoz egy meglévő szobához
                    </Link>
                    .
                </h3>
            ) : (
                <div className="w-full ">
                    {joinedRooms.map((room, index) => (
                        <div key={room.id}>
                            {index !== 0 && <hr className="" />}
                            <RoomItem room={room} filesToShare={null} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
