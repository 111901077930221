import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userService } from '../Api/Services/UserService';
import { useLoading } from '../Context/Providers/LoadingProvider';
import UserContext from '../Context/Providers/UserProvider';

interface LoginProviderCallbackProps {}

const LoginProviderCallback: React.FunctionComponent<LoginProviderCallbackProps> = () => {
    const { provider } = useParams<string>();
    const { setNewUser } = useContext(UserContext);

    const navigate = useNavigate();

    const { startLoading, finnishLoading } = useLoading();

    useEffect(() => {
        if (provider) {
            startLoading('provider-login');
            userService
                .providerLoginCallback(provider, window.location.search)
                .then((user) => {
                    setNewUser(user);
                    navigate('/');
                })
                .finally(() => {
                    finnishLoading('provider-login');
                });
        }
        // eslint-disable-next-line
    }, [provider]);

    return <h1>Callback {provider}</h1>;
};

export default LoginProviderCallback;
