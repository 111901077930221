import { UserModel } from '../../Models/user.model';
import request, { Method, RootUrl } from '../request';

interface providerLoginRedirectResponse {
    redirect_url: string;
}

class UserService {
    async getCurrentUser() {
        return request<UserModel>(`/auth/current-user`, Method.GET);
    }

    async login(email: string, password: string): Promise<UserModel> {
        return request<UserModel>(`/auth/login`, Method.POST, {
            data: {
                email: email,
                password: password
            }
        });
    }

    async register(email: string, password: string): Promise<UserModel> {
        return request<UserModel>(`/auth/register`, Method.POST, {
            data: {
                email: email,
                password: password
            }
        });
    }

    async logout() {
        return request(`/auth/logout`, Method.POST);
    }

    async getProviderLoginRedirect(provider: string) {
        return request<providerLoginRedirectResponse>(`/auth/social/provider-login-redirect/${provider}`, Method.GET);
    }

    async providerLoginCallback(provider: string, queryParams: string) {
        return request<UserModel>(`/auth/social/provider-login-callback/${provider}${queryParams}`, Method.GET);
    }

    async getCookies() {
        return request(`/sanctum/csrf-cookie`, Method.GET, {}, RootUrl);
    }
}

export const userService = new UserService();
