import { Avatar } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { useUser } from '../../Context/Providers/UserProvider';
import { RoomModel } from '../../Models/room.model';
import DocumentDuplicateIcon from '@heroicons/react/outline/DocumentDuplicateIcon';
import RoomIcon from '../Room/RoomIcon';

interface RoomItemProps {
    room: RoomModel;
    filesToShare: File[] | null;
}

const RoomItem: React.FunctionComponent<RoomItemProps> = ({ room, filesToShare }) => {
    const { user } = useUser();

    return (
        <Link to={`/room/${room.id}`} state={{ filesToShare: filesToShare }}>
            <div className="w-full p-4 flex justify-between">
                <div className="flex w-full grow align-middle items-center">
                    <RoomIcon roomType={room.type} />
                    <span className="ml-2 font-bold text-lg text-black hover:text-orange-600"> {room.name.length > 30 ? room.name.substring(0, 30) + '...' : room.name}</span>
                    {room.files.length > 0 && (
                        <div className="ml-4 flex items-center">
                            <DocumentDuplicateIcon className="h-4 w-4" />

                            <span className=" mr-2">{room.files.length}</span>
                        </div>
                    )}
                </div>
                {room.users && (
                    <div className="flex -space-x-4">
                        {room.users
                            .filter((x) => x.id !== user?.id)
                            .map((user) => (
                                <Avatar key={user.id} img={user.avatar ?? ''} rounded={true} stacked={true} size="sm" />
                            ))}
                    </div>
                )}
            </div>
        </Link>
    );
};

export default RoomItem;
