import Echo from 'laravel-echo';
import { useState, useEffect, useRef } from 'react';
import { FileSizeConvertToReadable } from '../../../../Api/Services/HelperFunctions/FileSizeConvertToReadable';
import { FileModel } from '../../../../Models/file.model';
import P2PFileSend from './P2PFileSend';
import DocumentIcon from '@heroicons/react/outline/DocumentIcon';
import XIcon from '@heroicons/react/outline/XIcon';
import { Button } from 'flowbite-react';

interface LocalFileManagerProps {
    echo: Echo;
    roomId: string;
    currentDeviceId: string;
    handleFileRemove: CallableFunction;
    localFiles: FileModel[];
}

interface FileSendRequest {
    requestByDeviceId: string;
    done: boolean;
    fileId: string;
    readyToSend: boolean;
}

const LocalFileManager: React.FunctionComponent<LocalFileManagerProps> = ({ echo, roomId, currentDeviceId, localFiles, handleFileRemove }) => {
    const [fileSends, setFileSends] = useState<FileSendRequest[]>([]);
    const fileSendsRef = useRef<FileSendRequest[]>([]);

    useEffect(() => {
        echo.channel(`rtc-connection.${roomId}.${currentDeviceId}`).listen('P2PFileSendRequest', function (fileSendRequest: FileSendRequest) {
            fileSendRequest.done = false;
            fileSendRequest.readyToSend = !fileSendsRef.current.some((x) => x.readyToSend && x.requestByDeviceId === fileSendRequest.requestByDeviceId);
            console.log('send request', fileSendRequest.requestByDeviceId, fileSendRequest.fileId);
            fileSendsRef.current = [...fileSendsRef.current, fileSendRequest];
            setFileSends(fileSendsRef.current);
            console.log('file send request', fileSendsRef.current);
        });

        return () => {
            console.log('leaving file send rquest');

            echo.channel(`rtc-connection.${roomId}.${currentDeviceId}`).stopListening('P2PFileSendRequest');
            echo.leave(`rtc-connection.${roomId}.${currentDeviceId}`);
        };
        // eslint-disable-next-line
    }, []);

    const setFileSendDone = (fileId: string, deviceId: string) => {
        fileSendsRef.current = fileSendsRef.current.filter((x) => !(x.fileId === fileId && x.requestByDeviceId === deviceId));

        let newReadyToSend = fileSendsRef.current.filter((x) => x.requestByDeviceId === deviceId)[0];

        if (newReadyToSend) {
            newReadyToSend.readyToSend = true;
            fileSendsRef.current = fileSendsRef.current.map((x) => (x.fileId === newReadyToSend.fileId && x.requestByDeviceId === newReadyToSend.requestByDeviceId ? newReadyToSend : x));

            console.log('NEW reday to send', fileSendsRef.current);
        }
        setFileSends(fileSendsRef.current);
    };

    return (
        <div className="mt-8 p-4 shadow-md bg-white rounded border-dotted border-green-700">
            <p className="font-bold underline mb-3 text-lg">Helyi fájlok</p>
            {localFiles.length === 0 && <p>Még nem töltöttél fel egy fájlt sem.</p>}
            {localFiles.map((file, index) => (
                <div className="my-2" key={file.id}>
                    {index !== 0 && <hr />}
                    <div key={file.id} className="flex flex-row content-center mt-2">
                        <DocumentIcon className="h-6 w-6 stroke-green-700" />
                        <span className="ml-2 w-64">{file.fileName.length > 30 ? file.fileName.substring(0, 30) + '...' : file.fileName}</span>
                        <span>({FileSizeConvertToReadable(file.fileSize)})</span>
                        <Button
                            size="xs"
                            color="failure"
                            className="ml-auto"
                            onClick={(e) => {
                                e.currentTarget.disabled = true;

                                handleFileRemove(file.id);
                            }}
                        >
                            <XIcon className="h-4 w-4" />
                        </Button>
                    </div>
                    {fileSends.length > 0 && (
                        <div className="mx-12 my-2 border-t">
                            {fileSends
                                .filter((x) => x.readyToSend && x.fileId === file.id)
                                .map((fileSend) =>
                                    file.localFile && !fileSend.done ? (
                                        <P2PFileSend
                                            echo={echo}
                                            file={file}
                                            localFile={file.localFile}
                                            roomId={roomId}
                                            currentDeviceId={currentDeviceId}
                                            toDeviceId={fileSend.requestByDeviceId}
                                            key={'p2p-file-send-to-' + fileSend.requestByDeviceId + fileSend.fileId}
                                            setFileSendDone={setFileSendDone}
                                        />
                                    ) : (
                                        <span key={'p2p-file-send-empty-' + fileSend.requestByDeviceId + fileSend.fileId}></span>
                                    )
                                )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default LocalFileManager;
