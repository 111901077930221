import { useNavigate } from 'react-router-dom';
import { roomService } from './Api/Services/RoomService';
import { RoomType } from './Models/room.model';
import { useEffect } from 'react';
import { useLoading } from './Context/Providers/LoadingProvider';

interface CreateRoomShortcutProps {
    type: RoomType;
}

const CreateRoomShortcut: React.FunctionComponent<CreateRoomShortcutProps> = ({ type }) => {
    const naviagate = useNavigate();
    const { startLoading, finnishLoading } = useLoading();

    useEffect(() => {
        startLoading('shortcut-new-room');
        roomService
            .createRoom(type)
            .then((room) => {
                naviagate(`/room/${room.id}`, { state: { room: room, filesToShare: null } });
            })
            .catch(() => {
                naviagate(`/`);
                finnishLoading('shortcut-new-room');
            });
        //eslint-disable-next-line
    }, []);

    return null;
};

export default CreateRoomShortcut;
