import axios from 'axios';
import Echo from 'laravel-echo';
import { Channel } from 'pusher-js';
import { createContext, useContext, useEffect, useState } from 'react';
import { ApiBaseUrl } from '../../Api/request';
import { useUser } from './UserProvider';

interface EchoContextInterface {
    echo: Echo;
}

interface EchoProviderPropsInterface {
    children: JSX.Element;
}

export const deviceIdStorageKey = 'deviceId';

const EchoContext = createContext<EchoContextInterface>({ echo: new Echo({}) });

export const EchoProvider = ({ children }: EchoProviderPropsInterface) => {
    const [echo, setEcho] = useState<Echo | null>(null);
    const { user } = useUser();

    useEffect(() => {
        //eslint-disable-next-line
        const puher = require('pusher-js');

        const newEcho = new Echo({
            broadcaster: 'pusher',
            key: 'c689c8d9e475fbb471d5',
            cluster: 'eu',
            foreTLS: true,
            encryption: true,
            authorizer: (channel: Channel) => {
                return {
                    authorize: (socketId: string, callback: CallableFunction) => {
                        axios
                            .post(user ? `${ApiBaseUrl}/device/auth-channel` : `${ApiBaseUrl}/device/auth-channel-guest`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                            .then((response) => {
                                callback(false, response.data);
                            })
                            .catch((error) => {
                                callback(true, error);
                            });
                    }
                };
            }
        });

        setEcho(newEcho);
    }, [user]);

    if (!echo) {
        return <></>;
    }

    return <EchoContext.Provider value={{ echo }}>{echo ? children : null}</EchoContext.Provider>;
};

export default EchoContext;

export function useEcho() {
    return useContext(EchoContext);
}
