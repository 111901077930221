import { FormEvent, useRef, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from '../../Api/Services/UserService';
import UserContext from '../../Context/Providers/UserProvider';
import { Label, TextInput, Button, Spinner } from 'flowbite-react';

interface RegisterProps {
    actionLoading: CallableFunction;
    setModalActionLoading: CallableFunction;
    close: CallableFunction;
}

const Register: React.FunctionComponent<RegisterProps> = ({ actionLoading, setModalActionLoading, close }) => {
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfirmRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const { setNewUser } = useContext(UserContext);

    const [registerLoading, setRegisterLoading] = useState<boolean>(false);

    const handleRegister = async (event: FormEvent) => {
        event.preventDefault();

        if (emailRef.current && passwordRef.current) {
            if (passwordRef.current.value !== passwordConfirmRef.current?.value) {
                alert('A két megadott jelszó nem egyezik egymással!');
                return;
            }

            if (!passwordRef.current.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,}$/gm)) {
                alert('A jelszónak legalább 8 karakter hosszúnak kell lennie és tartalmaznia kell legalább 1 számot, 1 kisbetűt és 1 nagybetűt!');
                return;
            }

            setRegisterLoading(true);
            setModalActionLoading(true);

            userService
                .register(emailRef.current.value, passwordRef.current.value)
                .then((user) => {
                    setNewUser(user);
                    navigate('/');
                    close();
                })
                .catch((e) => {
                    if (e?.response?.data?.message) {
                        alert(e?.response?.data?.message);
                    }
                })
                .finally(() => {
                    setRegisterLoading(false);
                    setModalActionLoading(false);
                });
        }
    };

    return (
        <form onSubmit={handleRegister} className="flex flex-col gap-4 w-full">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email" value="Email cím" />
                </div>
                <TextInput id="email" type="email" placeholder="Email cím" required={true} ref={emailRef} autoComplete="email" />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password" value="Jelszó" />
                </div>
                <TextInput id="password" type="password" required={true} ref={passwordRef} placeholder="Jelszó" autoComplete="new-password" minLength={8} />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password_confirmation" value="Jelszó megerősítése" />
                </div>
                <TextInput id="password_confirmation" type="password" required={true} ref={passwordConfirmRef} minLength={8} placeholder="Jelszó megerősítése" autoComplete="new-password" />
            </div>

            <Button type="submit" size="sm" disabled={actionLoading()}>
                {registerLoading && <Spinner light={true} className="mr-2 w-4 h-4" color="warning" />}
                Regisztráció
            </Button>
        </form>
    );
};

export default Register;
