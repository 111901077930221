import CloudIcon from '@heroicons/react/outline/CloudIcon';
import DualArrowsIcon from '../../Components/Icons/DualArrowsIcon';

interface RoomIconProps {
    roomType: string;
    className?: string;
}

const RoomIcon: React.FunctionComponent<RoomIconProps> = ({ roomType, className }) => {
    if (roomType === 'cloud') {
        return <CloudIcon className={'h-6 w-6 stroke-sky-700 ' + className} />;
    }
    if (roomType === 'p2p') {
        return <DualArrowsIcon className={'h-6 w-6 fill-green-700 ' + className} />;
    }

    return <></>;
};

export default RoomIcon;
