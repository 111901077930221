import { Modal } from 'flowbite-react';
import QrScanner from 'qr-scanner';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { roomService } from '../Api/Services/RoomService';
import { useLoading } from '../Context/Providers/LoadingProvider';

interface QrReaderModalProps {
    show: boolean;
    close: CallableFunction;
}

const QrReaderModal: React.FunctionComponent<QrReaderModalProps> = ({ show, close }) => {
    const qrVideoRef = useRef<HTMLVideoElement | null>(null);
    const [qrResult, setQrResult] = useState<string | null>(null);
    const qrScanner = useRef<QrScanner>();
    const [cameraAvailable, setCameraAvailable] = useState<boolean>(true);

    const { startLoading, finnishLoading } = useLoading();
    const navigate = useNavigate();

    useEffect(() => {
        const clearQrScanner = () => {
            if (qrScanner.current) {
                qrScanner.current.stop();
                qrScanner.current.destroy();
                qrScanner.current = undefined;
                document.querySelectorAll('.scan-region-highlight, .scan-region-highlight-svg, .code-outline-highlight').forEach((elem) => {
                    elem.remove();
                });
            }
        };

        return () => {
            clearQrScanner();
        };
        // eslint-disable-next-line
    }, []);

    const joinRoomId = async (id: string) => {
        startLoading('qr-join');

        roomService
            .joinRoomId(id)
            .then((room) => {
                navigate(`/room/${room.id}`, { state: { room: room } });
            })
            .finally(() => {
                finnishLoading('qr-join');
            });
    };

    useEffect(() => {
        if (!qrResult) {
            return;
        }
        const regex = new RegExp(`^${process.env.REACT_APP_BASE_URL}/join/(?<id>[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})$`, 'gm');
        const regexResult = regex.exec(qrResult);
        if (regexResult?.groups?.id) {
            try {
                joinRoomId(regexResult?.groups?.id);
            } catch (error) {
                alert('Room does not exists');
            }
        } else {
            alert('Wrong qr code');
        }
        // eslint-disable-next-line
    }, [qrResult]);

    useEffect(() => {
        if (qrVideoRef.current) {
            QrScanner.hasCamera().then((hasCamera) => {
                setCameraAvailable(hasCamera);
                if (hasCamera && qrVideoRef.current) {
                    if (!qrScanner.current) {
                        qrScanner.current = new QrScanner(
                            qrVideoRef.current,
                            (result) => {
                                if (result?.data) {
                                    setQrResult(result.data);
                                }
                            },
                            {
                                preferredCamera: 'environment',
                                highlightScanRegion: true,
                                highlightCodeOutline: true
                            }
                        );
                    }

                    if (show) {
                        qrScanner.current.start();
                    } else {
                        qrScanner.current.stop();
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [show]);

    return (
        <Modal show={show} size="md" popup={true} onClose={() => close()}>
            <Modal.Header className="ml-4">QR kód beolvasása</Modal.Header>
            <Modal.Body className="mt-2">{cameraAvailable ? <video ref={qrVideoRef}></video> : <h1>A kamera nem elérhető!</h1>}</Modal.Body>
        </Modal>
    );
};

export default QrReaderModal;
