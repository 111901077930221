import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { roomService } from '../../Api/Services/RoomService';
import { RoomModel } from '../../Models/room.model';
import CloudRoom from './RoomTypes/CloudRoom/CloudRoom';
import P2PRoom from './RoomTypes/P2PRoom/P2PRoom';
import { useEcho } from '../../Context/Providers/EchoProvider';
import { UserModel } from '../../Models/user.model';
import { useUser } from '../../Context/Providers/UserProvider';
import { Avatar, Button } from 'flowbite-react';
import ShareIcon from '@heroicons/react/outline/ShareIcon';
import ShareRoomModal from './ShareRoomModal';
import RoomIcon from './RoomIcon';

interface RoomProps {}

const Room: React.FunctionComponent<RoomProps> = () => {
    const { id } = useParams<string>();
    const navigate = useNavigate();

    const { echo } = useEcho();

    const [room, setRoom] = useState<RoomModel | undefined>();

    const presentUsersRef = useRef<UserModel[]>([]);
    const [presentUsers, setPresentUsers] = useState<UserModel[]>(presentUsersRef.current);

    const [editingRoomName, setEditingRoomName] = useState<boolean>(false);

    const [roomName, setRoomName] = useState<string>(room?.name ?? 'Névtelen szoba');

    const [showShareModal, setShowShareModal] = useState<boolean>(false);

    const { user } = useUser();

    useEffect(() => {
        if (room) {
            echo.join(`room-present-devices.${room.id}`)
                .here((users: UserModel[]) => {
                    presentUsersRef.current = users;

                    /*if (room.type === 'p2p') {
                        const presendDevicesFiles: FileModel[] = [];

                        devices.forEach((device) => {
                            presendDevicesFiles.push(...device.files);
                        });

                        remoteFilesRef.current = presendDevicesFiles;
                        setRemoteFiles(remoteFilesRef.current);
                    }*/

                    setPresentUsers(presentUsersRef.current);

                    console.log('HERE', presentUsersRef.current);
                })
                .joining((user: UserModel) => {
                    presentUsersRef.current = [...presentUsers, user];
                    setPresentUsers(presentUsersRef.current);
                    console.log('JOINING', presentUsersRef.current);
                })
                .leaving((user: UserModel) => {
                    presentUsersRef.current = presentUsersRef.current.filter((x) => x.id !== user.id);
                    setPresentUsers(presentUsersRef.current);
                    console.log('LEAVING', user);

                    /*if (room.type === 'p2p') {
                        const leftDeviceFileIds = remoteFiles.map((x) => x.id);
                        remoteFilesRef.current = remoteFilesRef.current.filter((remoteFile) => !leftDeviceFileIds.includes(remoteFile.id));
                        setRemoteFiles(remoteFilesRef.current);
                    }*/
                })
                .error((error: any) => {
                    console.error('ERROR', error);
                });

            return () => {
                echo.leave(`room-present-devices.${room.id}`);
            };
        }
        // eslint-disable-next-line
    }, [room]);

    useEffect(() => {
        if (typeof id == 'undefined') {
            navigate('/room/404');
        }

        if (!room && id) {
            roomService
                .getRoom(id)
                .then((roomById) => {
                    setRoom(roomById);
                    setRoomName(roomById.name);
                })
                .catch(() => {
                    navigate('/room/404');
                });
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!editingRoomName && roomName !== room?.name && room?.name !== undefined) {
            setRoom({ ...room, name: roomName });

            if (room?.id && room?.name) {
                roomService.updateRoomName(room.id, room.name);
            }
        }
        // eslint-disable-next-line
    }, [editingRoomName]);

    const roomTypeComponent = () => {
        switch (room?.type) {
            case 'cloud':
                return <CloudRoom room={room} />;
            case 'p2p':
                return <P2PRoom room={room} />;
            default:
                return <>none</>;
        }
    };

    const closeShareRoomModal = () => {
        setShowShareModal(false);
    };

    if (!room) {
        return <></>;
    }

    return (
        <div className="w-full p-2">
            <ShareRoomModal show={showShareModal} close={closeShareRoomModal} room={room} />

            <div className="w-full flex justify-between">
                <div className="flex items-center">
                    <RoomIcon roomType={room.type} className="mr-2" />
                    {editingRoomName ? (
                        <input
                            value={roomName}
                            disabled={!editingRoomName}
                            onChange={(e) => setRoomName(e.target.value)}
                            maxLength={255}
                            required
                            autoFocus={true}
                            minLength={3}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') setEditingRoomName(false);
                            }}
                            onBlur={(e) => setEditingRoomName(false)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-base md:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-bold w-56  md:w-64 lg:w-96"
                        />
                    ) : (
                        <h2
                            className="font-bold text-base md:text-xl px-1 my-1 cursor-pointer border-dashed  border border-transparent hover:border-gray-400 w-fit max-w-md break-all mr-3"
                            onClick={() => setEditingRoomName(!editingRoomName)}
                        >
                            {room.name.length > 30 ? room.name.substring(0, 30) + '...' : room.name}
                        </h2>
                    )}
                </div>
                <div className="flex">
                    {presentUsers.length > 0 && (
                        <div className="flex -space-x-4">
                            {presentUsers
                                .filter((x) => x.email !== user?.email)
                                .map((presentUser) => (
                                    <Avatar key={presentUser.id} img={presentUser.avatar ?? ''} rounded={true} stacked={true} size="sm" status="online" statusPosition="top-right" />
                                ))}
                        </div>
                    )}

                    <Button size="xs" className="ml-4" onClick={() => setShowShareModal(true)}>
                        <ShareIcon className="h-6 w-6" />
                        Megosztás
                    </Button>
                </div>
            </div>

            {roomTypeComponent()}
        </div>
    );
};

export default Room;
