import { useEffect, useState } from 'react';
import { roomService } from '../../Api/Services/RoomService';
import { useLoading } from '../../Context/Providers/LoadingProvider';
import { RoomModel } from '../../Models/room.model';
import CreateRoomModal from '../CreateRoomModal';
import RoomItem from '../Landing/RoomItem';

interface ShareFilesProps {}

const ShareFiles: React.FunctionComponent<ShareFilesProps> = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [joinedRooms, setJoinedRooms] = useState<RoomModel[]>([]);
    const { startLoading, finnishLoading } = useLoading();
    const [showCreateRoomModal, setShowCreateRoomModal] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            finnishLoading('get-share-target-files');
        }, 3500);

        startLoading('get-share-target-files');
        navigator.serviceWorker.addEventListener('message', function (event) {
            let files = event.data;

            setFiles(files);
            finnishLoading('get-share-target-files');
        });

        roomService.getJoinedRooms().then((rooms) => {
            setJoinedRooms(rooms);
        });

        //eslint-disable-next-line
    }, []);

    const closeCreateRoomModal = () => {
        setShowCreateRoomModal(false);
    };

    return (
        <>
            {files.length > 0 && (
                <div className="p-2 w-full flex flex-col justify-center">
                    <p className="text-lg w-full text-center">{files.length} fájl megosztása</p>
                    <CreateRoomModal show={showCreateRoomModal} close={closeCreateRoomModal} filesToShare={files} />
                    <div className="w-full text-center underline font-bold text-orange-600 hover:text-blue-500 cursor-pointer" onClick={() => setShowCreateRoomModal(true)}>
                        Új szoba létrehozása
                    </div>
                    <div className="w-full ">
                        {joinedRooms.map((room, index) => (
                            <div key={room.id}>
                                {index !== 0 && <hr className="" />}
                                <RoomItem room={room} filesToShare={files} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ShareFiles;
