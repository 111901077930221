import { createContext, useEffect, useState, useContext } from 'react';
import { userService } from '../../Api/Services/UserService';
import { UserModel } from '../../Models/user.model';
import { useLoading } from './LoadingProvider';

interface UserContextInterface {
    user: UserModel | null;
    setNewUser: CallableFunction;
    logout: CallableFunction;
}

interface UserProviderPropsInterface {
    children: JSX.Element;
}

const UserContext = createContext<UserContextInterface>({ user: null, setNewUser: () => {}, logout: () => {} });

export const UserProvider = ({ children }: UserProviderPropsInterface) => {
    const [user, setUser] = useState<UserModel | null>(null);

    const { startLoading, finnishLoading } = useLoading();

    useEffect(() => {
        startLoading('get-cookies');
        userService
            .getCookies()
            .then(() => {
                loadUser();
            })
            .finally(() => {
                finnishLoading('get-cookies');
            });
        //eslint-disable-next-line
    }, []);

    const loadUser = () => {
        startLoading('get-current-user');

        userService
            .getCurrentUser()
            .then((user) => {
                setUser(user);
            })

            .finally(() => {
                finnishLoading('get-current-user');
            });
    };

    const setNewUser = (userModel: UserModel) => {
        setUser(userModel);
    };

    const logout = () => {
        userService.logout().then(() => {
            setUser(null);
            loadUser();
        });
    };

    return <UserContext.Provider value={{ user, setNewUser, logout }}>{children}</UserContext.Provider>;
};

export default UserContext;

export function useUser() {
    return useContext(UserContext);
}
