import { Modal, Spinner } from 'flowbite-react';
import { useState } from 'react';
import { userService } from '../../Api/Services/UserService';
import Login from './Login';
import Register from './Register';

interface AuthModalProps {
    show: boolean;
    close: CallableFunction;
    formType: FormType;
    switchFormType: CallableFunction;
}

export enum FormType {
    LOGIN = 'login',
    REGISTER = 'register'
}

const AuthModal: React.FunctionComponent<AuthModalProps> = ({ show, close, formType, switchFormType }) => {
    const [githubLoginLoading, setGithubLoginLoading] = useState<boolean>(false);
    const [gooogleLoginLoading, setGoogleLoginLoading] = useState<boolean>(false);
    const [facebookLoginLoading, setFacebookLoginLoading] = useState<boolean>(false);

    const [modalActionLoading, setModalActionLoading] = useState<boolean>(false);

    const socialLogin = (provider: string, setLoading: CallableFunction) => {
        setLoading(true);

        userService
            .getProviderLoginRedirect(provider)
            .then((response) => {
                window.location.href = response.redirect_url;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const modalHeaderText = () => {
        switch (formType) {
            case FormType.REGISTER:
                return 'Regisztráció';
            case FormType.LOGIN:
                return 'Bejelentkezés';
        }
    };

    const modalSwitchText = () => {
        switch (formType) {
            case FormType.REGISTER:
                return 'Bejelentkezés';
            case FormType.LOGIN:
                return 'Regisztráció';
        }
    };

    const actionLoading = () => {
        return githubLoginLoading || gooogleLoginLoading || facebookLoginLoading || modalActionLoading;
    };

    return (
        <Modal show={show} size="md" popup={true} onClose={() => close()}>
            <Modal.Header className="ml-4">{modalHeaderText()}</Modal.Header>
            <hr />
            <Modal.Body className="mt-2">
                {formType === FormType.LOGIN ? (
                    <Login setModalActionLoading={setModalActionLoading} actionLoading={actionLoading} close={close} />
                ) : (
                    <Register setModalActionLoading={setModalActionLoading} actionLoading={actionLoading} close={close} />
                )}
                <button
                    type="button"
                    className="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 mr-2 mb-2 mt-2 justify-center px-5 py-2.5 w-full"
                    disabled={actionLoading()}
                    onClick={() => socialLogin('github', setGithubLoginLoading)}
                >
                    {!githubLoginLoading ? (
                        <svg className="mr-2 -ml-1 w-4 h-4" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                            <path
                                fill="currentColor"
                                d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                            ></path>
                        </svg>
                    ) : (
                        <Spinner light={true} className="mr-2 w-4 h-4" color="warning" />
                    )}
                    Belépés Githubbal
                </button>

                <button
                    type="button"
                    className="text-white bg-[#eb4132] hover:bg-[#eb4132]/90 focus:ring-4 focus:outline-none focus:ring-[#eb4132]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#eb4132]/55 mr-2 mb-2 w-full justify-center"
                    disabled={actionLoading()}
                    onClick={() => socialLogin('google', setGoogleLoginLoading)}
                >
                    {!gooogleLoginLoading ? (
                        <svg
                            className="mr-2 -ml-1 w-4 h-4"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="google"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 488 512"
                        >
                            <path
                                fill="currentColor"
                                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                            ></path>
                        </svg>
                    ) : (
                        <Spinner light={true} className="mr-2 w-4 h-4" color="warning" />
                    )}
                    Belépés Googlel
                </button>

                <button
                    type="button"
                    className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2 w-full justify-center"
                    disabled={actionLoading()}
                    onClick={() => socialLogin('facebook', setFacebookLoginLoading)}
                >
                    {!facebookLoginLoading ? (
                        <svg
                            className="mr-2 -ml-1 w-4 h-4"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="facebook-f"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="currentColor"
                                d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"
                            ></path>
                        </svg>
                    ) : (
                        <Spinner light={true} className="mr-2 w-4 h-4" color="warning" />
                    )}
                    Belépés Facebookkal
                </button>

                <p className="text-gray-500 dark:text-gray-400 text-center">
                    {formType === FormType.LOGIN ? 'Még nincs fiókod?' : 'Már van fiókod?'}&nbsp;
                    <span
                        className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                        onClick={() => switchFormType(formType === FormType.LOGIN ? FormType.REGISTER : FormType.LOGIN)}
                    >
                        {modalSwitchText()}
                        <svg aria-hidden="true" className="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </span>
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default AuthModal;
