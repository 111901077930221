import { RoomCodeModel, RoomModel, RoomType } from '../../Models/room.model';
import request, { Method } from '../request';

class RoomService {
    async getRoom(id: string) {
        return request<RoomModel>(`/room/${id}`, Method.GET);
    }

    async getJoinedRooms() {
        return request<RoomModel[]>(`/room/joined-rooms`, Method.GET);
    }

    async createRoom(type: RoomType) {
        return request<RoomModel>(`/room/create`, Method.POST, {
            data: {
                type: type
            }
        });
    }

    async generateCode(id: string) {
        return request<RoomCodeModel>(`/room/generate-code`, Method.POST, {
            data: {
                roomId: id
            }
        });
    }

    async joinRoomId(id: string) {
        return request<RoomModel>(`/room/join/room-id`, Method.POST, {
            data: {
                roomId: id
            }
        });
    }

    async joinRoomCode(code: number) {
        return request<RoomModel>(`/room/join/room-code`, Method.POST, {
            data: {
                code: code
            }
        });
    }

    async updateRoomName(id: string, name: string) {
        return request<RoomModel>(`/room/update-name`, Method.POST, {
            data: {
                room_id: id,
                name: name
            }
        });
    }
}

export const roomService = new RoomService();
