export function FileSizeConvertToReadable(bytes: number, perSecond: boolean = false): string {
    const postfix = perSecond ? '/s' : '';
    if (bytes > 1000000000) {
        return (bytes / 1000000000).toFixed(2) + ' GB' + postfix;
    }

    if (bytes > 1000000) {
        return (bytes / 1000000).toFixed(2) + ' MB' + postfix;
    }

    if (bytes > 1000) {
        return (bytes / 1000).toFixed(2) + ' KB' + postfix;
    }

    return bytes + ' b' + postfix;
}
