import { fileService } from '../../../../Api/Services/FileService';
import { FileModel } from '../../../../Models/file.model';
import { RoomModel } from '../../../../Models/room.model';
import FileDropzone from '../../Components/FileDropzone';
import { useEffect, useRef, useState } from 'react';
import FileCard from './FileCard';
import { useEcho } from '../../../../Context/Providers/EchoProvider';
import useDevice from '../../../../Context/Hooks/useDevice';
import DownloadIcon from '@heroicons/react/outline/DownloadIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import { Spinner } from 'flowbite-react';
import { useLocation } from 'react-router-dom';

interface CloudRoomProps {
    room: RoomModel;
}

interface FileAddedToRoomPayload {
    files: FileModel[];
}

interface FilesRemovedPayload {
    fileIds: string[];
}

const CloudRoom: React.FunctionComponent<CloudRoomProps> = ({ room }) => {
    const { echo } = useEcho();

    const roomFilesRef = useRef<FileModel[]>(room?.files ?? []);
    const [roomFiles, setRoomFiles] = useState<FileModel[]>(roomFilesRef.current);

    const { device } = useDevice();
    const location = useLocation();

    useEffect(() => {
        echo.channel(`room-files.${room.id}`).listen('FilesAddedToRoom', (newFilePayload: FileAddedToRoomPayload) => {
            if (newFilePayload.files) {
                const newFiles = newFilePayload.files.filter(
                    (x) => !roomFilesRef.current.find((roomFile) => roomFile.id === x.id) && !(x.clientId && roomFilesRef.current.find((roomFile) => roomFile.clientId === x.clientId))
                );
                roomFilesRef.current = [...roomFilesRef.current, ...newFiles];
                setRoomFiles(roomFilesRef.current);
            }
        });

        echo.channel(`room-files.${room.id}`).listen('FilesRemovedFromRoom', (filesRemoved: FilesRemovedPayload) => {
            if (filesRemoved.fileIds.length > 0) {
                roomFilesRef.current = roomFilesRef.current.filter((x) => !filesRemoved.fileIds.includes(x.id));
                setRoomFiles(roomFilesRef.current);
            }
        });

        return () => {
            echo.channel(`room-files.${room.id}`).stopListening('FileAddedToRoom');
            echo.channel(`room-files.${room.id}`).stopListening('FilesRemovedFromRoom');
            echo.leave(`room-files.${room.id}`);
        };
        // eslint-disable-next-line
    }, []);

    const removeFile = (file: FileModel) => {
        roomFilesRef.current = roomFilesRef.current.filter((f) => f.id !== file.id);
        setRoomFiles([...roomFilesRef.current]);
    };

    const handleFileAdd = (acceptedFiles: File[]) => {
        const filesToUpload = acceptedFiles.map((file) => {
            return {
                fileName: file.name,
                fileSize: file.size,
                file: file,
                clientId: Math.random().toString(36)
            };
        });

        filesToUpload.forEach((fileToUpload) => {
            roomFilesRef.current.push({
                ...fileToUpload,
                uploading: true,
                selected: false,
                removing: true,
                id: '',
                roomId: room.id,
                device: { id: device.id ?? '' },
                localFile: null,
                path: '',
                thumbnail: '',
                publicUrl: ''
            });
            setRoomFiles([...roomFilesRef.current]);

            fileService.addCloudFile(room.id, fileToUpload).then((newFile) => {
                roomFilesRef.current = roomFilesRef.current.map((x) => {
                    if (x.clientId && newFile.clientId && x.clientId === newFile.clientId) {
                        newFile.uploading = false;
                        return newFile;
                    }
                    return x;
                });
                setRoomFiles([...roomFilesRef.current]);
            });
        });
    };

    const changeFileSelected = (fileId: string, selected: boolean) => {
        roomFilesRef.current = roomFilesRef.current.map((x) => {
            if (x.id === fileId) {
                x.selected = selected;
            }
            return x;
        });

        setRoomFiles([...roomFilesRef.current]);
    };

    const downloadSelectedFiles = () => {
        roomFiles.forEach((x) => {
            if (x.selected) {
                fileService.downloadCloudFile(x.id).then((blob) => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = x.fileName;
                    link.style.display = 'none';

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            }
        });
    };
    const removeSeletedFiles = () => {
        roomFilesRef.current = roomFilesRef.current.map((x) => {
            if (x.selected) {
                x.removing = true;
            }
            return x;
        });

        setRoomFiles([...roomFilesRef.current]);

        roomFiles.forEach((x) => {
            if (x.selected) {
                fileService
                    .removeCloudFile(x)
                    .then(() => {
                        removeFile(x);
                    })
                    .finally(() => {});
            }
        });
    };

    const saveToDropbox = () => {
        var options = {
            files: roomFiles
                .filter((x) => x.selected)
                .map((x) => {
                    return {
                        fileName: x.fileName,
                        url: x.publicUrl
                    };
                }),
            success: function () {
                alert('Fájlok sikeresen mentve a Dropbox fiókodba.');
            }
        };

        // @ts-ignore
        window.Dropbox.save(options);
    };

    useEffect(() => {
        try {
            let selectedFile = roomFiles.find((x) => x.selected);
            if (selectedFile && roomFiles.filter((x) => x.selected).length === 1) {
                let saveToDriveButton = document.createElement('div');
                saveToDriveButton.dataset.src = selectedFile.publicUrl;
                saveToDriveButton.dataset.filename = selectedFile.fileName;
                saveToDriveButton.dataset.sitename = 'Szakdoga';
                saveToDriveButton.classList.add('g-savetodrive');

                let driveContainer = document.getElementById('save_to_drive_condainer');
                if (driveContainer) {
                    driveContainer.innerHTML = '';
                    driveContainer.appendChild(saveToDriveButton);

                    // @ts-ignore
                    window.gapi.savetodrive.go();
                }
            } else {
                let driveContainer = document.getElementById('save_to_drive_condainer');
                if (driveContainer) {
                    driveContainer.innerHTML = '';
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [roomFiles]);

    useEffect(() => {
        if (location.state?.filesToShare) {
            handleFileAdd(location.state.filesToShare);
            window.history.replaceState({}, document.title);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <div className="mt-6">
            <div className="flex items-center flex-wrap">
                <span className="mr-3">
                    <b>{roomFiles.filter((x) => x.selected).length}</b> kiválasztott fájl
                </span>
                <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 disabled:opacity-75 disabled:cursor-not-allowed mr-3"
                    disabled={roomFiles.filter((x) => x.selected).length <= 0}
                    onClick={() => {
                        downloadSelectedFiles();
                    }}
                >
                    <DownloadIcon className="w-5 h-5" /> Letöltés
                </button>

                <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-600  rounded-lg hover:bg-red-700 disabled:opacity-75 disabled:cursor-not-allowed mr-3"
                    onClick={removeSeletedFiles}
                    disabled={roomFiles.filter((x) => x.selected).length <= 0 || roomFiles.some((x) => x.selected && x.device.id !== device.id) || roomFiles.some((x) => x.removing)}
                >
                    {roomFiles.some((x) => x.removing) ? <Spinner size="sm" color="warning" className="mx-0.5" /> : <TrashIcon className="w-5 h-5" />} Törlés
                </button>

                <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-white text-black rounded-lg hover:bg-gray-100 disabled:opacity-75 disabled:cursor-not-allowed mr-3 mt-3 md:mt-0"
                    disabled={roomFiles.filter((x) => x.selected).length <= 0}
                    onClick={() => {
                        saveToDropbox();
                    }}
                >
                    <svg className="w-6 h-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 40" version="1.1">
                        <path
                            d="m12.5 0l-12.5 8.1 8.7 7 12.5-7.8-8.7-7.3zm-12.5 21.9l12.5 8.2 8.7-7.3-12.5-7.7-8.7 6.8zm21.2 0.9l8.8 7.3 12.4-8.1-8.6-6.9-12.6 7.7zm21.2-14.7l-12.4-8.1-8.8 7.3 12.6 7.8 8.6-7zm-21.1 16.3l-8.8 7.3-3.7-2.5v2.8l12.5 7.5 12.5-7.5v-2.8l-3.8 2.5-8.7-7.3z"
                            fill="#007EE5"
                        />
                    </svg>
                    Mentés DropBox-ra
                </button>

                <div id="save_to_drive_condainer"></div>
            </div>
            <div className="mt-3 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 gap-4">
                {roomFiles.map((file) => (
                    <FileCard file={file} key={file.id + file.clientId} removeFile={removeFile} changeFileSelected={changeFileSelected} />
                ))}
            </div>

            <FileDropzone handleFileAdd={handleFileAdd} />
        </div>
    );
};

export default CloudRoom;
