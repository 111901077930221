import { useNavigate } from 'react-router-dom';
import { roomService } from '../Api/Services/RoomService';
import { RoomType } from '../Models/room.model';
import { Modal } from 'flowbite-react';
import CloudIcon from '@heroicons/react/outline/CloudIcon';
import DualArrowsIcon from '../Components/Icons/DualArrowsIcon';
import { useLoading } from '../Context/Providers/LoadingProvider';

interface CreateRoomProps {
    show: boolean;
    close: CallableFunction;
    filesToShare: File[] | null;
}

const CreateRoomModal: React.FunctionComponent<CreateRoomProps> = ({ show, close, filesToShare }) => {
    const naviagate = useNavigate();
    const { startLoading, finnishLoading } = useLoading();

    const createRoom = async (type: RoomType) => {
        startLoading('create-room');
        roomService
            .createRoom(type)
            .then((room) => {
                naviagate(`/room/${room.id}`, { state: { room: room, filesToShare: filesToShare } });
                close();
            })
            .finally(() => {
                finnishLoading('create-room');
            });
    };

    return (
        <Modal show={show} size="xl" popup={true} onClose={() => close()}>
            <Modal.Header className="ml-4">Szoba létrehozása</Modal.Header>
            <hr />
            <Modal.Body className="mt-2 flex flex-col md:flex-row gap-3">
                <div
                    onClick={() => createRoom('cloud')}
                    className="md:basis-1/2 h-60 border-dashed border-sky-700 border-4 rounded flex justify-center items-center flex-col cursor-pointer transition-colors hover:bg-sky-50 font-bold text-sky-700"
                >
                    <CloudIcon className="w-10 h-10 stroke-sky-700" />
                    Felhő alapú szoba
                </div>
                <div
                    onClick={() => createRoom('p2p')}
                    className="md:basis-1/2 h-60 border-dashed border-green-700 border-4 rounded flex justify-center items-center flex-col cursor-pointer transition-colors hover:bg-green-50 font-bold text-green-700"
                >
                    <DualArrowsIcon className="w-10 h-10 fill-green-700" />
                    Közvetlen kapcsolatú szoba
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateRoomModal;
