import { Progress } from 'flowbite-react';
import Echo from 'laravel-echo';
import { useEffect, useRef, useState } from 'react';
import { FileSizeConvertToReadable } from '../../../../Api/Services/HelperFunctions/FileSizeConvertToReadable';
import { p2pService } from '../../../../Api/Services/P2PService';
import { DeviceModel } from '../../../../Models/device.model';
import { FileModel } from '../../../../Models/file.model';

interface P2PFileReceiveProps {
    file: FileModel;
    currentDeviceId: string;
    roomId: string;
    echo: Echo;
    setRemoteFile: CallableFunction;
}

interface ConnectionOfferPayload {
    offer: RTCSessionDescriptionInit;
    senderDevice: DeviceModel;
}

interface ConnectionIceCandidatePayload {
    candidates: RTCIceCandidate[];
    senderDevice: DeviceModel;
    from: string;
}

const rtcConfiguration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };

const P2PFileReceive: React.FunctionComponent<P2PFileReceiveProps> = ({ file, currentDeviceId, roomId, echo, setRemoteFile }) => {
    const iceCandidates = useRef<RTCIceCandidate[]>([]);
    const connection = useRef<RTCPeerConnection>();

    const receiveBuffer = useRef<any[]>([]);
    const receiveSize = useRef<number>(0);
    const [receiveSizeProgress, setReceiveSizeProgress] = useState<number>(0);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const startTimeStamp = useRef<number>(Date.now());

    useEffect(() => {
        echo.channel(`rtc-connection.${roomId}.${currentDeviceId}.${file.id}`).listen('ConnectionOffer', (offerPayload: ConnectionOfferPayload) => {
            console.log('echo offer', offerPayload);
            if (offerPayload.offer) {
                connection.current = new RTCPeerConnection(rtcConfiguration);

                connection.current.ondatachannel = (event: RTCDataChannelEvent) => {
                    const dataChanel = event.channel;

                    dataChanel.binaryType = 'arraybuffer';

                    dataChanel.onopen = (e) => {
                        startTimeStamp.current = Date.now();
                        console.log('RECE CHANNEL OPEN');
                    };
                    dataChanel.onclose = (e) => {
                        console.log('RECE CHANNEL CLOSE', e);
                    };

                    dataChanel.onmessage = (event) => {
                        if (receiveBuffer && event.data) {
                            receiveBuffer.current.push(event.data);
                            receiveSize.current += event.data.byteLength;

                            setReceiveSizeProgress(receiveSize.current);
                            setElapsedTime(Date.now() - startTimeStamp.current);

                            console.log('recieve ', event.data, receiveSize.current, file.fileSize);

                            if (receiveSize.current >= file.fileSize) {
                                console.log('done');

                                const received = new Blob(receiveBuffer.current);
                                setRemoteFile(received);
                            }
                        }
                    };
                };

                connection.current.onicecandidate = (e) => {
                    if (e.candidate) {
                        iceCandidates.current?.push(e.candidate);
                    }
                };

                if (!offerPayload.offer.sdp?.endsWith('\n')) {
                    offerPayload.offer.sdp = offerPayload.offer.sdp + '\n';
                }

                connection.current
                    .setRemoteDescription(offerPayload.offer)
                    .then(() => {
                        if (connection.current) {
                            return connection.current.createAnswer();
                        }
                    })
                    .then((answer) => {
                        if (connection.current) {
                            connection.current.setLocalDescription(answer).then(() => {
                                if (connection.current && connection.current.localDescription) {
                                    p2pService.answerOffer(connection.current.localDescription, roomId, offerPayload.senderDevice.id, file.id);
                                }
                            });
                        }
                    });
            }
        });

        echo.channel(`rtc-connection.${roomId}.${currentDeviceId}.${file.id}`).listen('NewIceCandidate', (e: ConnectionIceCandidatePayload) => {
            console.log('echo send ice', e);

            if (e.candidates) {
                e.candidates.forEach((candidate) => {
                    if (connection.current) {
                        connection.current.addIceCandidate(candidate);
                    }
                });

                if (e.from === 'send') {
                    console.log('Sending receive ice');

                    p2pService.iceCandidate(iceCandidates.current, roomId, 'receive', e.senderDevice.id, file.id);
                }
            }
        });

        return () => {
            echo.channel(`rtc-connection.${roomId}.${currentDeviceId}`).stopListening('ConnectionAnswer');
            echo.channel(`rtc-connection.${roomId}.${currentDeviceId}`).stopListening('NewIceCandidate');
            echo.leave(`rtc-connection.${roomId}.${currentDeviceId}`);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="flex justify-between">
                <div className="text-base font-medium dark:text-white tabular-nums">Letöltés: {FileSizeConvertToReadable(receiveSizeProgress / (elapsedTime / 1000), true)}</div>
                <div className="text-base font-medium dark:text-white tabular-nums">{((receiveSizeProgress / file.fileSize) * 100).toFixed(1)}%</div>
            </div>
            <Progress progress={(receiveSizeProgress / file.fileSize) * 100} labelProgress={true} />
        </div>
    );
};

export default P2PFileReceive;
