import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Components/Layout';
import CreateRoomShortcut from './CreateRoomShortcut';
import JoinRoom from './Pages/JoinRoom';
import Landing from './Pages/Landing/Landing';
import LoginProviderCallback from './Pages/LoginProviderCallback';
import Room from './Pages/Room/Room';
import RoomNotFound from './Pages/Room/RoomNotFound';
import ShareFiles from './Pages/ShareFiles/ShareFiles';

export interface IAppProps {}

const App: React.FunctionComponent<IAppProps> = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Landing />}></Route>

                    <Route path="/shortcuts">
                        <Route path="create-cloud" element={<CreateRoomShortcut type={'cloud'} />} />
                        <Route path="create-p2p" element={<CreateRoomShortcut type={'p2p'} />} />
                    </Route>

                    <Route path="/join">
                        <Route index element={<JoinRoom />} />
                        <Route path=":id" element={<JoinRoom />} />
                    </Route>

                    <Route path="/room/404" element={<RoomNotFound />}></Route>
                    <Route path="/room/:id" element={<Room />}></Route>
                    <Route path="/login/:provider/callback" element={<LoginProviderCallback />}></Route>

                    <Route path="share-files" element={<ShareFiles />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
