import { FileModel } from '../../Models/file.model';
import request, { Method } from '../request';

interface P2PFileInterface {
    fileName: string;
    fileSize: number;
}

interface CloudFileInterface {
    clientId: string;
    fileName: string;
    fileSize: number;
    file: File;
}

class FileService {
    async addP2PFiles(roomId: string, files: P2PFileInterface[]): Promise<FileModel[]> {
        return request<FileModel[]>(`/file/p2p/add`, Method.POST, {
            data: {
                roomId: roomId,
                files: files
            }
        });
    }

    async removeP2PFiles(roomId: string, fileIds: string[]): Promise<FileModel[]> {
        return request<FileModel[]>(`/file/p2p/remove`, Method.POST, {
            data: {
                roomId: roomId,
                fileIds: fileIds
            }
        });
    }

    async leaveP2PRoom(roomId: string) {
        return request(`/file/p2p/device-leave`, Method.POST, {
            data: {
                roomId: roomId
            }
        });
    }

    async requestP2PFileSend(roomId: string, fileId: string) {
        return request(`/file/p2p/request-file-send`, Method.POST, {
            data: {
                roomId: roomId,
                fileId: fileId
            }
        });
    }

    async addCloudFile(roomId: string, file: CloudFileInterface): Promise<FileModel> {
        let formData = new FormData();
        formData.append('file', file.file);
        formData.append('roomId', roomId);
        formData.append('fileName', file.fileName);
        formData.append('fileSize', file.fileSize.toString());
        formData.append('clientId', file.clientId);

        return request<FileModel>(`/file/cloud/add`, Method.POST, {
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async downloadCloudFile(fileId: string): Promise<Blob> {
        return request<Blob>(`/file/cloud/download/${fileId}`, Method.GET, {
            responseType: 'blob'
        });
    }

    async removeCloudFile(file: FileModel): Promise<FileModel> {
        return request<FileModel>(`/file/cloud/remove/${file.id}`, Method.DELETE);
    }
}

export const fileService = new FileService();
