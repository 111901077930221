import { DeviceModel } from '../../Models/device.model';
import request, { Method } from '../request';

class DeviceService {
    async generateDeviceId() {
        return request<DeviceModel>(`/device/generate-id`, Method.POST);
    }

    async verifyDeviceId(deviceId: string) {
        return request<DeviceModel>(`/device/verify-id`, Method.POST, {
            data: {
                id: deviceId
            }
        });
    }
}

export const deviceService = new DeviceService();
