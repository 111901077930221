import DownloadIcon from '@heroicons/react/outline/DownloadIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import { Spinner } from 'flowbite-react';
import { useState } from 'react';
import { fileService } from '../../../../Api/Services/FileService';
import { FileSizeConvertToReadable } from '../../../../Api/Services/HelperFunctions/FileSizeConvertToReadable';
import useDevice from '../../../../Context/Hooks/useDevice';
import { FileModel } from '../../../../Models/file.model';

interface FileCardProps {
    file: FileModel;
    removeFile: CallableFunction;
    changeFileSelected: CallableFunction;
}

const FileCard: React.FunctionComponent<FileCardProps> = ({ file, removeFile, changeFileSelected }) => {
    const DeviceContext = useDevice();

    const [removing, setRemoving] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);

    const handleFileDownload = (file: FileModel) => {
        setDownloading(true);
        fileService
            .downloadCloudFile(file.id)
            .then((blob) => {
                let blobUrl = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = blobUrl;
                link.download = file.fileName;
                link.style.display = 'none';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .finally(() => {
                setDownloading(false);
            });
    };

    const handleRemove = () => {
        setRemoving(true);
        fileService
            .removeCloudFile(file)
            .then(() => {
                removeFile(file);
            })
            .finally(() => {
                setRemoving(false);
            });
    };

    return (
        <label className="w-100 h-[150px] md:h-[200px] rounded-lg shadow-lg relative bg-cover" style={{ backgroundImage: `url('${file.thumbnail}')` }} htmlFor={'file-check-' + file.id}>
            {file.uploading && (
                <div className="w-100 h-[150px] md:h-[200px] flex justify-center items-center">
                    <Spinner size={'lg'} />
                </div>
            )}
            <div className="absolute h-[150px] md:h-[200px] bottom-0 w-full flex items-end justify-between" style={{ background: 'linear-gradient(180deg, transparent 40%, rgba(255,255,255,1) 85%)' }}>
                <div>
                    <p className="mb text-sm font-medium text-gray-900">{file.fileName.length > 20 ? file.fileName.slice(0, 19) + '...' : file.fileName}</p>
                    <span className="text-xs text-gray-500">{FileSizeConvertToReadable(file.fileSize)}</span>
                </div>
            </div>
            {!file.uploading && (
                <div className="absolute bottom-1 right-1">
                    <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 disabled:opacity-75"
                        disabled={!!downloading}
                        onClick={() => {
                            handleFileDownload(file);
                        }}
                    >
                        {downloading ? <Spinner size="sm" className="mx-0.5" /> : <DownloadIcon className="w-5 h-5" />}
                    </button>
                </div>
            )}
            {!file.uploading && file.device.id === DeviceContext.device.id && (
                <div className="absolute right-1 top-1">
                    <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-600  rounded-lg hover:bg-red-700 disabled:opacity-75"
                        onClick={handleRemove}
                        disabled={!!removing || file.removing}
                    >
                        {removing || file.removing ? <Spinner size="sm" color="warning" className="mx-0.5" /> : <TrashIcon className="w-5 h-5" />}
                    </button>
                </div>
            )}
            {!file.uploading && (
                <input
                    id={'file-check-' + file.id}
                    type="checkbox"
                    checked={file.selected ?? false}
                    onChange={(e) => {
                        changeFileSelected(file.id, e.target.checked);
                    }}
                    className="absolute left-2 top-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                />
            )}
        </label>
    );
};

export default FileCard;
