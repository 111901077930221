import { Link } from 'react-router-dom';

interface RoomNotFoundProps {}

const RoomNotFound: React.FunctionComponent<RoomNotFoundProps> = () => {
    return (
        <div className="p-2 w-full flex justify-center">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg text-center flex flex-col items-center">
                <p>A keresett szoba nem található!</p>
                <Link to="/" className="underline font-bold text-orange-600 hover:text-blue-500 mt-3 ">
                    Vissza a főoldalra
                </Link>
            </div>
        </div>
    );
};

export default RoomNotFound;
