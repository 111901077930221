import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { deviceIdStorageKey } from '../Context/Providers/DeviceProvider';
export const RootUrl: string = process.env.REACT_APP_API_BASE_URL ?? '';
export const ApiBaseUrl: string = `${process.env.REACT_APP_API_BASE_URL ?? ''}/api`;

export enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers) {
        config.headers.deviceId = localStorage.getItem(deviceIdStorageKey) ?? '';
    }

    //config.data.append('deviceId', );
    return config;
});

axios.interceptors.response.use(
    async (response) => response,
    async (error: AxiosError) => {
        console.log(error.message);
        return Promise.reject(error);
    }
);

async function request<T = void>(endpoint: string, method: Method = Method.GET, requestParams: Object = {}, baseUrl: string = ApiBaseUrl) {
    const url = baseUrl + endpoint;

    const requestConfig = {
        method,
        url,
        ...requestParams
    };

    const { data: response } = await axios.request<T>(requestConfig);

    return response;
}

export default request;
