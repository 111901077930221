import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DeviceProvider } from './Context/Providers/DeviceProvider';
import { UserProvider } from './Context/Providers/UserProvider';
import { EchoProvider } from './Context/Providers/EchoProvider';
import { LoadingProvider } from './Context/Providers/LoadingProvider';
import 'flowbite';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <LoadingProvider>
        <UserProvider>
            <DeviceProvider>
                <EchoProvider>
                    <App />
                </EchoProvider>
            </DeviceProvider>
        </UserProvider>
    </LoadingProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
